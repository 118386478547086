.MuiListItemText-root.details {
  display: inline-flex;
  justify-content: space-between;
}

.details .MuiListItemText-primary{
  padding-left:10px;
  font-size: 12px;
}
.details .MuiListItemText-secondary{

    font-size: 12px;
  }