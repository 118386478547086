.left .MuiListItemText-primary {
    background-color:#BADDE0;
    border-radius: 5px;
    padding:10px;
    display: inline-block;  
  
}

.right .MuiListItemText-primary {
    background-color:#79d9e2;
    border-radius: 5px;
    padding:10px;
    display: inline-block;
    
  
}